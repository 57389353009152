@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

:root{
    --main-color:#443;
    --border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
    --border-radius-hover:4% 95% 6% 95%/95% 4% 92% 5%;
    --border:.2rem solid var(--main-color);
    --border-hover:.2rem dashed var(--main-color);
}

*{
    font-family: 'Poppins', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    outline: none; border:none;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .2s linear;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 7rem;
    scroll-behavior: smooth;
}

section{
    padding:2rem 9%;
}

.heading{
    font-size: 9rem;
    text-transform: uppercase;
    color:transparent;
    -webkit-text-stroke: .05rem var(--main-color);
    letter-spacing: .2rem;
    text-align: center;
    pointer-events: none;
    position: relative;
}

.heading span{
    position: absolute;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color:var(--main-color);
    font-size: 3rem;
}

.btn{
    display: inline-block;
    padding:.9rem 1.5rem;
    border-radius: var(--border-radius);
    color:var(--main-color);
    background: none;
    border:var(--border);
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.7rem;
}

.btn:hover{
    border-radius: var(--border-radius-hover);
    border:var(--border-hover);
}

.header{
    position: fixed;
    top:0; left: 0; right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    background:#fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    padding:2rem 9%;
}

.header .logo{
    color:var(--main-color);
    font-size: 2.3rem;
}

.header .logo i{
    padding-left: .5rem;
}

.header .navbar a{
    margin:0 1rem;
    font-size: 1.7rem;
    color:var(--main-color);
}

.header .btn{
    margin-top: 0;
}

#menu-btn{
    font-size: 3rem;
    color:var(--main-color);
    cursor: pointer;
    display: none;
}

.home{
    min-height: 100vh;
    padding-top: 12rem;
    background: url(./image/home-bg.jpg) no-repeat;
    background-position: center;
    background-size: cover;
}

.home .row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:1.5rem;
}

.home .row .content{
    flex:1 1 42rem;
}

.home .row .image{
    flex:1 1 42rem;
    padding-top: 10rem;
    text-align: center;
}

.home .row .image img{
    height:35rem;
    animation: float 4s linear infinite;
}

@keyframes float{
    0%, 100%{
        transform: translateY(0rem);
    }
    50%{
        transform: translateY(-7rem);
    }
}

.home .row .content h3{
    font-size: 6.5rem;
    color:var(--main-color);
    text-transform: uppercase;
}

.home .image-slider{
    text-align: center;
    padding:3rem 0;
}

.home .image-slider img{
    height:9rem;
    margin:0 .5rem;
    cursor: pointer;
    margin-top: 5rem;
}

.home .image-slider img:hover{
    transform: translateY(-2rem);
}

.about .row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:1.5rem;
}

.about .row .image{
    flex:1 1 42rem;
}

.about .row .image img{
    width: 100%;
    animation:aboutImage 4s linear infinite;
}

@keyframes aboutImage {
    0%, 100%{
        transform: scale(.9);
        border-radius: var(--border-radius-hover);
    }
    50%{
        transform: scale(.8);
        border-radius: var(--border-radius);
    }
}

.about .row .content{
    flex:1 1 42rem;
}

.about .row .content .title{
    color:var(--main-color);
    font-size: 3rem;
    line-height: 1.8;
}

.about .row .content p{
    color:var(--main-color);
    font-size: 1.5rem;
    line-height: 1.8;
    padding:1rem 0;
}

.about .row .content .icons-container{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    padding-top: 3rem;
}

.about .row .content .icons-container .icons{
    flex:1 1 15rem;
    padding:1.5rem;
    text-align: center;
    border:var(--border);
    border-radius: var(--border-radius);
}

.about .row .content .icons-container .icons img{
    height: 5rem;
}

.about .row .content .icons-container .icons h3{
    font-size: 1.7rem;
    padding-top: 1rem;
    color:var(--main-color);
}

.menu{
    background: url(./image/menu-bg.jpg) no-repeat;
    background-position: center;
    background-size: cover;
}

.menu .box-container{
    display: flex;
    flex-wrap: wrap;
    gap:2rem;
    justify-content: center;
}

.menu .box-container .box{
    flex:1 1 42rem;
    padding:2rem;
    border:var(--border);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    gap:1.5rem;
    margin-left: 6rem;
}

.menu .box-container .box:hover{
    border:var(--border-hover);
    border-radius: var(--border-radius-hover);
}

.menu .box-container .box img{
    height: 8rem;
    margin-left: -6rem;
}

.menu .box-container .box .content h3{
    font-size: 2.2rem;
    color:var(--main-color);
    line-height: 1.8;
}

.menu .box-container .box .content p{
    font-size: 1.6rem;
    color:var(--main-color);
    line-height: 1.8;
    padding:1rem 0;
}

.menu .box-container .box .content span{
    font-size: 2rem;
    color:var(--main-color);
    line-height: 1.8;
}

.review .review-slider{
    padding:7rem 0;
}

.review .review-slider .box{
    border:var(--border);
    border-radius: var(--border-radius);
    text-align: center;
    position: relative;
    z-index: 0;
    padding:2rem;
}

.review .review-slider .box .fa-quote-left{
    position: absolute;
    top:2rem; left: 2.5rem;
    font-size: 6rem;
    color:#ccc;
    z-index: -1;
}

.review .review-slider .box .fa-quote-right{
    position: absolute;
    bottom:2rem; right: 2.5rem;
    font-size: 6rem;
    color:#ccc;
    z-index: -1;
}

.review .review-slider .box:hover .fa-quote-left{
    top:-6.5rem;
}

.review .review-slider .box:hover .fa-quote-right{
    bottom:-6.5rem;
}

.review .review-slider .box img{
    height:7rem;
    width:7rem;
    border-radius: 50%;
    margin-bottom: .7rem;
}

.review .review-slider .box .stars{
    padding:.5rem 0;
}

.review .review-slider .box .stars i{
    font-size: 1.7rem;
    color:var(--main-color);
}

.review .review-slider .box p{
    font-size: 1.6rem;
    color:var(--main-color);
    padding:1rem 0;
    line-height: 1.8;
}

.review .review-slider .box h3{
    font-size: 2.2rem;
    color:var(--main-color);
    line-height: 1.8;
}

.review .review-slider .box span{
    font-size: 1.5rem;
    color:var(--main-color);
}

.swiper-pagination-bullet{
    height:1.5rem;
    width:1.5rem;
    border-radius: 0;
}

.swiper-pagination-bullet-active{
    background:var(--main-color);
}

.book{
    background:url(./image/book-bg.jpg) no-repeat;
    background-position: center;
    background-size: cover;
}

.book form{
    margin:0 auto 2rem auto;
    max-width: 60rem;
    border-radius: var(--border-radius-hover);
    padding:3rem;
    border:var(--border);
}

.book form .box{
    width:100%;
    padding:1rem 1.2rem;
    border-radius: .5rem;
    font-size: 1.6rem;
    background:none;
    text-transform: none;
    color:var(--main-color);
    border:var(--border);
    margin:.7rem 0;
}

.book form .box:focus{
    border:var(--border-hover);
}

.book form textarea{
    height:15rem;
    resize: none;
}

.footer .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
    gap:1.5rem;
}

.footer .box-container .box h3{
    font-size: 2.5rem;
    padding:1rem 0;
    color:var(--main-color);
}

.footer .box-container .box a{
    display: block;
    font-size: 1.5rem;
    padding:1rem 0;
    color:var(--main-color);
}

.footer .box-container .box a i{
    padding-right: .5rem;
}

.footer .box-container .box a:hover i{
    padding-right: 2rem;
}

.footer .credit{
    text-align: center;
    font-size: 2rem;
    padding:2rem 1rem;
    margin-top: 1rem;
    color:var(--main-color);
}

.footer .credit span{
    border-bottom: var(--border-hover);
}















/* media queries  */

@media(max-width:991px){

    html{
        font-size: 55%;
    }

    .header{
        padding:2rem;
    }

    section{
        padding:2rem;
    }

}

@media(max-width:768px){

    .heading{
        font-size: 6rem;
    }

    .heading span{
        font-size: 2.3rem;
    }

    #menu-btn{
        display: initial;
    }

    #menu-btn.fa-times{
        transform:rotate(180deg);
    }

    .header .navbar{
        position: absolute;
        top:99%; left:0; right:0;
        background:#fff;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    .header .navbar.active{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .header .navbar a{
        display: block;
        font-size: 2.2rem;
        margin:0;
        padding:1.5rem 2rem;
    }

    .home{
        text-align: center;
    }

    .home .row .content h3{
        font-size: 4rem;
    }

    .menu .box-container .box{
        margin-left: 0;
        margin-top: 6rem;
        flex-flow: column;
        text-align: center;
    }

    .menu .box-container .box img{
        margin-left: 0;
        margin-top: -6rem;
    }

}

@media(max-width:450px){

    html{
        font-size: 50%;
    }

    .home .row .image img{
        height: auto;
        width: 100%;
    }

}